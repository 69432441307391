import React from "react"
import { Navbar } from "react-bootstrap"



const Footer = () => (
<Navbar bg="success" variant="dark" className="d-flex justify-content-center mt-3">
    <Navbar.Text  >We can't improve your culture, but we can give you the tools to</Navbar.Text>
    
    
    
  </Navbar>
)

export default Footer
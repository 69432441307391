import React from "react";
import { Card, Form, Popover, OverlayTrigger, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { getQuestion, getExplanation, getQuestionTitle } from "./WestrumTexts";

const WestrumForm = ({ onRadioClick, scores, onButtonClick }) => {
  const displayQuestion = question => (
    <Card className="d-flex justify-content-center">
      <Card.Header className="d-flex justify-content-center ">
        {getQuestion(question)} &nbsp;
        <OverlayTrigger
          placement="right"
          overlay={
            <Popover id="popover-basic">
              <Popover.Title as="h3">
                {getQuestionTitle(question)}
              </Popover.Title>
              <Popover.Content>{getExplanation(question)}</Popover.Content>
            </Popover>
          }
        >
          <FontAwesomeIcon
            className="align-self-center"
            icon={faInfo}
            size="xs"
            color="grey"
            style={{ width: 20 }}
          />
        </OverlayTrigger>
      </Card.Header>
      <Card.Body className="d-flex justify-content-center">
        <fieldset>
          {[1, 2, 3, 4, 5, 6, 7].map(i => (
            <label key={question + i}>
              <input
                type="radio"
                checked={scores[question] === i}
                onChange={() => onRadioClick(question, i)}
              />{" "}
              {i}
              &nbsp; &nbsp;
            </label>
          ))}
        </fieldset>
      </Card.Body>
    </Card>
  );
  return (
    <>
      <p className="d-flex justify-content-center">
        Please rate the following statements from Strongly Disagree (1) to
        Strongly Agree (7).
      </p>
      <Form>
        {displayQuestion("cooperation")}
        {displayQuestion("messengers")}
        {displayQuestion("responsibility")}
        {displayQuestion("bridging")}
        {displayQuestion("failure")}
        {displayQuestion("novelty")}
        <Button onClick={onButtonClick} className="mt-3">
          Submit
        </Button>
      </Form>
    </>
  );
};

export default WestrumForm;

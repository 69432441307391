import React, { useEffect, useState } from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import { API } from "aws-amplify";
import WestrumForm from "./WestrumForm";
import { withRouter } from "react-router-dom";

const updateScores = (scores, name, val) => {
  return { ...scores, [name]: val };
};

const Respond = ({ match, history }) => {
  const [survey, setSurvey] = useState({});
  const [scores, setScores] = useState({
    cooperation: 4,
    messengers: 4,
    responsibility: 4,
    bridging: 4,
    failure: 4,
    novelty: 4
  });
  const postResponse = (scores, id) => {
    return API.post("westrum", `/surveys/${match.params.id}`, {
      body: {
        cooperation: scores.cooperation,
        messengers: scores.messengers,
        responsibility: scores.responsibility,
        bridging: scores.bridging,
        failure: scores.failure,
        novelty: scores.novelty
      }
    });
  };
  const handleSubmit = event => {
    console.log(event);
    event.preventDefault();
    (async () => {
      try {
        const result = await postResponse(scores, match.params.id);
        console.log(result);
        history.push("/thank-you");
      } catch (e) {
        console.log(e);
      }
    })();
  };
  useEffect(() => {
    (async () => {
      const mySurvey = await API.get("westrum", `/surveys/${match.params.id}`);
      setSurvey(mySurvey);
    })();
  }, [match]);
  return (
    <Jumbotron>
      <h1 className="d-flex justify-content-center">{survey.name}</h1>
      <WestrumForm
        onRadioClick={(idx, val) => setScores(updateScores(scores, idx, val))}
        scores={scores}
        onButtonClick={handleSubmit}
      />
    </Jumbotron>
  );
};

export default withRouter(Respond);

import React from "react";
import { Jumbotron } from "react-bootstrap";
import Table from "react-bootstrap/Table";

let AboutWestrum = () => {
    return (
        <Jumbotron>
            <h1>Westrum Culture Typology</h1>
            <hr />
            <h2>Introduction</h2>
        <p>I was first introduced to the Westrum Culture typology through the DORA research, condensed in the state of DevOps reports and the book Accelerate.
            I was drawn to this model as it firstly enables us to quantify what healthy culture is, measure good culture and connect this to concrete actions and focus areas.
            These are some powerful properties in a business where the consensus is that culture is the enabler, foundation and driver for changes of any magnitude,
            but where we have a tendency of claiming it is too fuzzy or vague to do something about.

            </p>
           <hr />
           <h2>The Model</h2>
           <p>
           Whoever wants to run the survey creates a survey. This gives them a link to share with team members, allowing them to gather responses. 
           As the results come in, Cultooling will show how the team scores on the Westrum Culture Model. 
           When the team has gathered the responses they want, they can consult Cultooling to get some actions and focus areas to work on.
           The results can also be used to spark discussions and reflections on the team, perhaps with an external facilitator.
           </p>
           <Table striped bordered size="sm" variant="dark" >
  <thead>
    <tr>
      <th>Pathological (Power-oriented)</th>
      <th>Bureaucratic (Process-oriented)</th>
      <th>Generative (Performance-oriented)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Low co-operation</td>
      <td>Modest co-operation</td>
      <td>High co-operation</td>
    </tr>
    
    <tr>
      <td>Messengers shot</td>
      <td>Messengers neglected</td>
      <td>Messengers trained</td>
    </tr>
    <tr>
      <td>Responsibilities shirked	</td>
      <td>Narrow responsibilities</td>
      <td>Risks are shared
</td>
    </tr>
    <tr>
      <td>Bridging discouraged</td>
      <td>Bridging tolerated	</td>
      <td>Bridging encouraged
</td>
    </tr>
    <tr>
      <td>Failure leads to scapegoating	</td>
      <td>Failure leads to justice	</td>
      <td>Failure leads to inquiry
</td>
    </tr>
    <tr>
      <td>Novelty crushed	</td>
      <td>Novelty leads to problems	</td>
      <td>Novelty implemented
</td>
    </tr>
    </tbody></Table>
    <p>
        These six parameters indicates good culture by flow and quality of information. 
        Most of us will have experienced behaviours from the left and middle column, and the few lucky ones have experienced the right most behaviours.
        Any organization is not static in the model, it can change from week to week, but simply being aware of the different patterns, and reflecting concrete
        events onto the model, gives us a language and toolset to do continuous improvement on the culture track.
        </p>
           <hr />
           <h2>Resources</h2>
            <ul>
                <li><a href="https://www.amazon.com/Accelerate-Software-Performing-Technology-Organizations/dp/1942788339/ref=sxin_0_sxwds-bia-wc1_0?keywords=accelerate&pd_rd_i=1942788339&pd_rd_r=11634a51-d2ca-416f-bbdb-48e0f10bf860&pd_rd_w=8T9tC&pd_rd_wg=PvNYc&pf_rd_p=39e7c2a0-69e1-4a3f-8d8f-4c4ee1aefdb5&pf_rd_r=C67VB363JR70D56JP6PS&psc=1&qid=1570905400">The book Accelerate by Forsgren et al.</a></li>
                <li><a href="https://continuousdelivery.com/implementing/culture/">https://continuousdelivery.com/implementing/culture/</a></li>
                <li><a href="https://cloud.google.com/solutions/devops/devops-culture-westrum-organizational-culture">https://cloud.google.com/solutions/devops/devops-culture-westrum-organizational-culture</a></li>
                <li><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1765804/pdf/v013p0ii22.pdf">Research by Ron Westrum</a></li>
            </ul>
          
           
            
        </Jumbotron>
    )
}

export default AboutWestrum
import React from "react";
import { render } from "react-dom";
import App from "./components/App";
import * as Sentry from "@sentry/browser"
import Amplify from "aws-amplify";
import config from "./config";

import { BrowserRouter as Router } from "react-router-dom";

Sentry.init({dsn: "https://082f4083ace9420fa2be50e85558f5d6@sentry.io/1886234"});

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "westrum",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
});
Amplify.configure({
  API: {
    endpoints: [
      {
        name: "westrum",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
});
render(
  <Router>
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
      integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
      crossOrigin="anonymous"
    />
    <App />
  </Router>,
  document.getElementById("root")
);

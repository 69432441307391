import React from 'react'

import Routes from './Routes'
import Navigation from "./Navigation"
import Footer from "./Footer"
import "./App.css"

import { Container } from 'react-bootstrap'

const App = () => {  
  return (
    <>
    <Navigation />
    <Container className="mt-3">
  <Routes />
  </Container>
  <Footer />
  </>
)
  }

export default App
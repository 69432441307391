const TEXTS = {
  header: {
    pathological: "Pathological",
    bureaucratical: "Bureaucratical",
    generative: "Generative"
  },
  cooperation: {
    color: "#28A745",
    title: "Cooperation",
    question: "On my team, information is actively sought",
    explanation:
      "To what degree is cooperation between team members encouraged.",
    resultHeader: {
      pathological: "Low co-operation",
      bureaucratical: "Modest co-operation",
      generative: "Cooperation is encouraged"
    },
    resultText: {
      pathological: `Cooperation is low. This might indicate teams lack the capabilities they need to fully solve the work items that are moving through the team.
                       Being explicit about knowledgesharing, particular for distributed teams, is key. From a management or leadership perspective ensuring that incentives and priorities are not adversively affecting team members ability or willingness to both seek out information and help each other.`,
      bureaucratical: `Cooperation happens, but it is not in the DNA of the team to have a high level for coorporation. Take steps to encourage team members to cooporate and knowledgeshare.
                         Consider solving more tasks in pairs. Invest in tooling to support the team in colloration. This is particularly important for distributed teams as some ommon and implicit communication channels are unavailable.`,
      generative: `You have the priviledge of a team with a high level of cooperation. Ask the team about their practices and what works for them. The challenge is now to make sure that you maintain a high level of collaboration by deliberate intent, not by pure luck.
                     Pay particular attention to how and if the team communication changes when there are challenges or incidents about. This could indicate points of weakness in the culture of collaboration`
    }
  },
  messengers: {
    color: "#009765",
    title: "Messengers",
    question: "Messengers are not punished when they deliver bad news",
    explanation:
      "When a team member is raising an issue, how is bad news received by the organization?",
    resultHeader: {
      pathological: "Messengers shot",
      bureaucratical: "Messengers neglected",
      generative: "Messengers are not shot"
    },
    resultText: {
      pathological: `Bringers of bad news are scolded, blamed or otherwised discouraged from repeat offenses. It is made clear that being informative about bad news are not appreciated in this organization. 
                        This means problems often does not surface until they are urgent and critical. While it might be too big a leap to start celebrating bad news, commit to blamelessly acknowledging them.
                        This can lead to an organization that might still be challenged in terms of solving issues proactively, but at least be more aware of upcoming challenges.
                        `,
      bureaucratical: `Neglecting messengers are a common trait of the bureaucratic organization. Tasks go to the backlog to die. Fixes are not prioritized, either because of a singular focus on feature delivery, or
                         because acting on the bad news is difficult. This is often seen in risk averse organizations, or organizations that are afraid to rock the boat. A common scenario is to neglect
                         upgrading infrastructure, because of not wanting to disturb the users. This works well until it becomes a larger task, requiring more downtime, or worse an incident, perhaps security related.
                         Start acting on the messengers. Reporters of bad news should be thanked, and their information acted on. Ideally the messengers will be thanked, told what actions will be taken to react, and when issue has been resolved, thanked again, and informed how progress has been made.
                         This will coach the organization to bring problem areas out into the open, so the company can act with confidence and effectiveness.`,
      generative: `Messengers are trained, the organization has successfully combatted the knee-jerk reaction of putting down bad news. This is a prerequisite for many other cultural practices, so hone your skills.
                     A next step could be experimenting with topics like Chaos Engineering, or pre-mortems to not just get what is present out in the open, but to extend that into the future.                
         `
    }
  },
  responsibility: {
    color: "#008578",
    title: "Risks are shared",
    question: "On my team, responsibilities are shared",
    explanation:
      "Are team members trying to shy away from responsibilities, or do we have a shared sense of accountability.",
    resultHeader: {
      pathological: "Responsibilities shirked",
      bureaucratical: "Narrow responsibilities",
      generative: "Risks are shared"
    },
    resultText: {
      pathological: `Responsibility and accountability are shunned. If quality, security or performance issues comes up, no team or group member claim responsibility.
                       If something is taking longer than expected, it is certain that everyone happily will point directly at someone else. 
                       Both quality and speed suffers as a consequence. Investigate whether incentives across functions are in direct conflict with each other.`,
      bureaucratical: `Teams and members take exactly the responsibility that is needed and defined. Well defined requirements and scopes will be wielded as weapons.
                         Strict adherence to process is the gold standard. Align goals and objectives across teams such that everyone contributes to the same end.
                         This means that innovation rate is relevant for all, not just developers, and stability and performance in production also is relevant for developers.
         `,
      generative: `Everyone takes end-to-end responsibility. As everyone is paying attention to and caring about the entire value stream, more eyes on the process lowers the risk.
                     Investigate what behaviours and values got you here. Cultivate and invest in them. Especially if you are growing your organisation.           
         `
    }
  },
  bridging: {
    color: "#00717C",
    title: "Bridging",
    question:
      "On my team, cross-functional collaboration is encouraged and rewarded",
    explanation:
      "Does the organization encourage cooperation across organizational units.",
    resultHeader: {
      pathological: "Bridging discouraged",
      bureaucratical: "Bridging tolerated",
      generative: "Bridging Encouraged"
    },
    resultText: {
      pathological: `You live in a world of silos. Handoffs and interteam dependencies requires meetings and long email chains. Information that could have been acted on early in a process, do not appear
                       until a handover is happening. This makes changes both difficult and expensive. Invite different functions to participate in design and early reviews. `,
      bureaucratical: `While some bridging happens it is tolerated rather than encouraged. It happens based on individual initiatives and in an unstructured way.
                         Invite different parts of the organizations to reviews or plannings to encourage collaboration. Invest in your team members assisting across function, with the faith that
                         that investment will be paid back over time.                 
         `,
      generative: `Further advice not available for this category yet`
    }
  },
  failure: {
    color: "#005C70",
    title: "Failure",
    question: "On my team, failure causes inquiry",
    explanation:
      "When failure inevitably happens, does the organization seek out justice, scapegoating or see it as an opportunity to learn.",
    resultHeader: {
      pathological: "Failure leads to scapegoating",
      bureaucratical: "Failure leads to justice",
      generative: "Failure leads to enquiry"
    },
    resultText: {
      pathological: `How an organization reacts to failures are one of the key indicators of culture. In your organization failure is about blame, avoiding getting held accountable for any short comings, and if possible use the failure as a powergrab.
                        This is a very tough spot to move out off, as this is almost malign behaviour. Start focusing on getting the facts straight in your analysis of failures.
                        The five whys as a root cause analysis tool might help out. Try to involve all relevant parties in the failure analysis in order to avoid just shoving the blame over the wall.`,
      bureaucratical: `Your organization is of the firm belief that someone, somewhere did something not inline with the process, as that is the only way something could have gone wrong.
                         That someone will need to be taken to justice. This can be decidedly damaging to the organization as it can shove people away from being open about missteps, and actually learn from their mistakes.
                         Look into Blameless Post-mortems as a technique for structured organizational learning.`,
      generative: `You have truly taken to the learning organization. Blameless postmortems and other structured learning techniques are used. Pay attention to the organizational bias, and ensure that you are in this high level of health on purpose rather than accidentially.`
    }
  },
  novelty: {
    color: "#2F4858",
    title: "Novelty",
    question: "On my team, new ideas are welcomed",
    explanation:
      "New findings and ideas can be problematic in some organizations, while in healthier organizations new ideas are implemented.",
    resultHeader: {
      pathological: "Novelty crushed",
      bureaucratical: "Novelty leads to problems",
      generative: "Novelty implemented"
    },
    resultText: {
      pathological: `New ideas, concepts and tools are struck down as they might upset the current power balance. There is a high resistance towards change. This often coincides with a high degree of Not-Invented-Here syndrome,
                       where homebrewn solutions are meticulously maintained, rather than using either COTS or open source tools. Pay attention to how senior engineers treat new hires, and juniors. They might be the carriers of this bias.
                       Whenever you have a new hire, do all you can to keep their fresh perspective fresh, and take them seriously when they point out dysfunctions, or try to bring in best practices from previous jobs.`,
      bureaucratical: `New concepts and themes are not actively crushed, but they are hard to spread across the organization and tends to give problems, as they do not fit into the current process or world view.
                         The worry can often be one of risk or lack of understanding. Focus your efforts on making it safe to experiment. This involves both accepting that experiments can fail, but also providing the tools and support
                         to make it quick and easy to actually do an experiment with a limited blast radius.`,
      generative: `You are in a good place where you implement novelty. Strive to become more ambitious. Set a target for how many of your experiments that should fail. Not because you want your experiments to fail, but because you want to be more ambitious.
                     Look into Objectives and Key Results. It is an organizational alignment technique. Google has 70% as their minimum success value.`
    }
  }
};

function getCategory(score) {
  if (score <= 3) {
    return "pathological";
  } else if (score <= 5) {
    return "bureaucratical";
  } else {
    return "generative";
  }
}
function getResultText(question, score) {
  return TEXTS[question].resultText[getCategory(score)];
}
function getHeaderText(question, score) {
  return TEXTS[question].resultHeader[getCategory(score)];
}
function getQuestion(question) {
  return TEXTS[question].question;
}
function getHeader(score) {
  return TEXTS.header[getCategory(score)];
}
function getExplanation(question) {
  return TEXTS[question].explanation;
}
function getQuestionTitle(question) {
  return TEXTS[question].title;
}
function getQuestionColor(question) {
  return TEXTS[question].color;
}

export {
  getHeader,
  getQuestion,
  getResultText,
  getHeaderText,
  getExplanation,
  getQuestionTitle,
  getQuestionColor
};

import React from "react"
import CardDeck from 'react-bootstrap/CardDeck'
import Card from "react-bootstrap/Card"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPoll, faMicrophone, faMicroscope } from '@fortawesome/free-solid-svg-icons'
import { Button, Col, Row } from "react-bootstrap"
import NewSurvey from "./NewSurvey"
import Jumbotron from "react-bootstrap/Jumbotron"
import { westrumUrl} from "./url_functions"
import NewsletterSignupForm from "./NewsletterSignup"

const Home = () => (
<>
<CardDeck>
  <Card>
  <FontAwesomeIcon className="align-self-center mt-3" icon={faPoll} size="6x" color="#28A745" />
    <Card.Body>
      <Card.Title className="align-self-center" >Create Survey</Card.Title>
      <Card.Text className="lead">
        Create a survey with a single click!<br /> Works out of the box without<br /> additional configuration.<br />
        <Button href="/new" className="mt-3">
            Start here
        </Button>
      </Card.Text>
    </Card.Body>
  </Card>
  <Card>
  <FontAwesomeIcon className="align-self-center mt-3" icon={faMicrophone} size="6x" color="#28A745" />
    <Card.Body>
      <Card.Title className="align-self-center">Gather Responses</Card.Title>
      <Card.Text className="lead">
        Share survey link with participants and watch as results stream in,
        through a simple, short form.
      </Card.Text>
    </Card.Body>
 
  </Card>
  <Card>
  <FontAwesomeIcon className="align-self-center mt-3" icon={faMicroscope} size="6x" color="#28A745" />
    <Card.Body>
      <Card.Title className="align-self-center">Gain Insights</Card.Title>
      <Card.Text className="lead">
       Get actionable recommendations<br /> based on your results.<br />
       Improve your culture today!
      </Card.Text>
    </Card.Body>
  </Card>
</CardDeck>

<br />
<Jumbotron >
  <Row>
    <Col><NewSurvey  /></Col>
    <Col><NewsletterSignupForm /></Col>
  </Row>


</Jumbotron>
<Card>
  <Card.Title className="ml-3 mt-3 display-4">Cultooling matters</Card.Title>
  <Card.Body>
    <p>Cultooling enables a simple way of quickly collection insights into the culture of your teams and organizations,
      and recommends actions that will help you move towards a healthy culture. It challenges the misconception that culture can't be measured, 
      is vague and fluffy, or requires expensive consultants running time consuming off site events to change.
    </p>
    <p>The survey is based on the <a href={westrumUrl()}>Westrum Culture Typology</a> that has been shown to drive software delivery performance and and the ability to meet or exceed business goals.
      The topics covered support quality and flow of information throughout the organization to enable continuous improvement and experimentation.
    </p>
    <p>Using this tool, you can create a survey in seconds, and instantly share it with the participants.
      Each participants spends only a few minutes on the survey, anonymously reporting their reality.
      Within a day you can kick off concrete initiatives taking steps towards building better culture.
    </p>
  </Card.Body>
</Card>
<br />
<br />
</>
)

export default Home
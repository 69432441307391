import React, { useEffect } from "react";
import * as d3 from "d3";
import { getQuestion, getQuestionColor } from "./WestrumTexts";
const Viz = ({ idx, data, numberOfResponses }) => {
  useEffect(() => {
    const val = data.sum / numberOfResponses;
    const bardata = data.data.slice(1).map(x => (x / numberOfResponses) * 100);

    const selector = `.svg${idx}`;
    const svg = d3.select(selector);

    const svgSize = svg.node().getBoundingClientRect();
    const w = svgSize.width;
    const h = svgSize.height;

    const margin = 30;
    const left = margin;
    const right = w - margin;
    const actualWidth = right - left;

    const scoreValues = 7;
    const sectionWidth = actualWidth / scoreValues;
    const minValue = 1;
    const maxValue = 7;
    const range = maxValue - minValue;
    const scale = actualWidth / range;

    const textMargin = 12;

    svg.selectAll("*").remove();
    svg
      .selectAll("rect")
      .data(bardata)
      .enter()
      .append("rect")
      .attr("x", (d, i) => left + i * sectionWidth)
      .attr("y", (d, i) => h - d * 0.3)
      .attr("width", sectionWidth)
      .attr("height", (d, i) => d * 0.3)
      .attr("fill", getQuestionColor(idx))
      .style("opacity", "0.5");

    svg
      .append("circle")
      .attr("cx", left + scale * (val - minValue))
      .attr("cy", 25)
      .attr("r", 15)
      .attr("fill", getQuestionColor(idx));
    svg
      .append("line")
      .attr("x1", left)
      .attr("y1", 25)
      .attr("x2", right)
      .attr("y2", 25)
      .style("stroke", getQuestionColor(idx))
      .style("stroke-width", "4px");

    svg
      .append("text")
      .attr("x", left + scale * (val - minValue) - textMargin)
      .attr("y", 30)
      .text(val.toFixed(1))
      .attr("fill", "white")
      .style("font-weight", "bold");
  });
  return (
    <div className="viz my-0">
      <h6 className="my-0">{getQuestion(idx)}</h6>
      <br />
      <svg width="100%" height={40} className={`svg${idx} mt-2`}></svg>
    </div>
  );
};

export default Viz;

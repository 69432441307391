import React, { useEffect, useState } from "react";
import { Jumbotron } from "react-bootstrap";
import Westrum from "./Westrum";
import { API } from "aws-amplify";
import Viz from "./Viz";
import { getHeader } from "./WestrumTexts";

const LoadOTron = ({ loading }) => loading && <Jumbotron>Loading</Jumbotron>;
const EmptySurvey = ({ id }) => (
  <Jumbotron>
    No results yet! Share this link with your participants:{" "}
    <a href={respondLink(id)}> {respondLink(id)}</a>
  </Jumbotron>
);
const Visualize = ({ votes, numberOfResponses, question }) => {
  return (
    <>
      <hr className="my-0" />
      <Viz
        className="mt-6"
        data={votes[question]}
        numberOfResponses={numberOfResponses}
        idx={question}
      />
      <br />
    </>
  );
};

const SurveySelector = ({ votes, surveyId, numberOfResponses }) => {
  if (numberOfResponses === 0) {
    return <EmptySurvey id={surveyId} />;
  } else {
    const questions = Object.keys(votes);
    const avgOverall =
      questions.reduce(
        (acc, key) => acc + votes[key].sum / numberOfResponses,
        0
      ) / questions.length;
    return (
      <>
        <h2>
          {avgOverall.toFixed(1)} - {getHeader(avgOverall)}
        </h2>
        {Object.keys(votes).map(question => (
          <Visualize
            key={question}
            votes={votes}
            numberOfResponses={numberOfResponses}
            question={question}
          />
        ))}
        <Westrum
          numberOfResponses={numberOfResponses}
          avgOverall={avgOverall}
          votes={votes}
        />
      </>
    );
  }
};
const respondLink = id => {
  return `http://${window.location.host}/respond/${id}`;
};

const ResponsePlural = ({ count }) =>
  count !== 1 ? <span>{count} responses</span> : <span>1 response</span>;

const SurveyResult = ({ match }) => {
  const [numberOfResponses, setNumberOfReponses] = useState(0);
  const [loading, setLoading] = useState(true);
  const [survey, setSurvey] = useState({ surveyName: "Loading" });
  const [votes, setVotes] = useState({
    cooperation: { sum: 0, data: new Array(8).fill(0) },
    messengers: { sum: 0, data: new Array(8).fill(0) },
    responsibility: { sum: 0, data: new Array(8).fill(0) },
    bridging: { sum: 0, data: new Array(8).fill(0) },
    failure: { sum: 0, data: new Array(8).fill(0) },
    novelty: { sum: 0, data: new Array(8).fill(0) }
  });
  useEffect(() => {
    // Fetch survey name in parallel
    (async () => {
      const mySurvey = await API.get(
        "westrum",
        `/surveys/admin/${match.params.id}`
      );
      setSurvey(mySurvey);
    })();
    function put(counter, value) {
      counter.sum += value;
      counter.data[value] += 1;
    }
    function addResponse(response) {
      put(votes.cooperation, response.cooperation);
      put(votes.messengers, response.messengers);
      put(votes.responsibility, response.responsibility);
      put(votes.bridging, response.bridging);
      put(votes.failure, response.failure);
      put(votes.novelty, response.novelty);
      setVotes(votes);
    }
    (async () => {
      // Fetch responses
      const responses = await API.get(
        "westrum",
        `/surveys/${match.params.id}/responses`
      );
      responses.forEach(addResponse);
      setNumberOfReponses(responses.length);
      // IDEA: use websocket to get real live feedback?
      setLoading(false);
    })();
  }, [match, votes]);
  return (
    <>
      <LoadOTron loading={loading} />
      <Jumbotron>
        <h1>
          {survey.surveyName}{" "}
          <small className="text-muted">
            {" "}
            (<ResponsePlural count={numberOfResponses} />)
          </small>
        </h1>
        <br />
        <SurveySelector
          className="d-flex mt-6"
          votes={votes}
          surveyId={survey.surveyId}
          numberOfResponses={numberOfResponses}
        />
      </Jumbotron>
    </>
  );
};

export default SurveyResult;

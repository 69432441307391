import React from "react";
import WestrumCategory from "./WestrumCategory";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { getResultText, getHeaderText } from "./WestrumTexts";

const CultureTheme = ({ idx, score }) => {
  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey={idx}>
          {getHeaderText(idx, score)} ({score.toFixed(1)})
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={idx}>
          <Card.Body>{getResultText(idx, score)}</Card.Body>
        </Accordion.Collapse>
      </Card.Header>
    </Card>
  );
};
const Westrum = ({ numberOfResponses, avgOverall, votes }) => {
  return (
    <>
      <Accordion defaultActiveKey="0">
        {Object.keys(votes).map(question => (
          <CultureTheme
            key={question}
            idx={question}
            score={votes[question].sum / numberOfResponses}
          />
        ))}
      </Accordion>

      <WestrumCategory score={avgOverall} />
    </>
  );
};

export default Westrum;

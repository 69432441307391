import React, {useState, useEffect} from "react"
import {API} from "aws-amplify"

import Jumbotron from "react-bootstrap/Jumbotron"
import Container from "react-bootstrap/Container"
const SurveyAdmin = ({match}) => {
    const [survey, setSurvey] = useState({name: "john", id:"1234"})
    useEffect(  () => {
        async function fetchData() {
            const mySurvey = await API.get("westrum", `/surveys/admin/${match.params.id}`)
            setSurvey(mySurvey)
        }
        fetchData()
    }, [match.params.id])
    const surveyResultLink = (id) => {
        return `http://${window.location.host}/surveys/${id}/results`
    }
    const surveyAdminLink = (id) => {
        return `http://${window.location.host}/surveys/${id}`
    }
    const respondLink = (id) => {
        return `http://${window.location.host}/respond/${id}`
    }
    return (
        <Container>
        <Jumbotron>
            <h1>{survey.name} Created</h1>
            <h4>Get responses by sharing this link with your participants:</h4>
            <p><a href={respondLink(survey.surveyId)}>{respondLink(survey.surveyId)}</a></p>
            <hr />
            <p>To return to this page bookmark this link</p>
            <p><a href={surveyResultLink(survey.adminId)}>{surveyAdminLink(survey.adminId)}</a></p>
            <hr />
            <p>See results here:</p>
            <p><a href={surveyResultLink(survey.adminId)}>{surveyResultLink(survey.adminId)}</a></p>

            </Jumbotron>
        </Container>
    )
}

export default SurveyAdmin
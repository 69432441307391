import React from "react"
import Jumbotron from "react-bootstrap/Jumbotron"
import { Container } from "react-bootstrap"

const ThankYou = () => {
    return (
        <Container>
        <Jumbotron>
            <h1>Thank you for participating in this survey</h1>
        </Jumbotron>
        </Container>
    )
}

export default ThankYou
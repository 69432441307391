import React, {useState} from "react"
import { API } from "aws-amplify"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Container from "react-bootstrap/Container"
import {withRouter } from "react-router-dom"

const postSurvey = (survey) => {
    return API.post("westrum", "/surveys", {
        body: survey
    })
}


const NewSurvey = withRouter(({history}) => {
    const [surveyName, setSurveyName] = useState("")
    const handleInputChange = (event) => {
        event.persist();
        setSurveyName(event.target.value)
    }

    const handleSubmit = (event) => {
        console.log(event)
        event.preventDefault();
        async function callAPI() {
        
            console.log(event)
            console.log(surveyName)
            try {
                const survey = await postSurvey({surveyName: surveyName})
                console.log(survey)
                history.push(`/surveys/${survey.adminId}`)
            } catch (e) {
                console.log(e);
            }
            }
            callAPI()
    
    }
    return(
        <Container>
          <h2>Create a survey</h2>
 
    <Form >
  <Form.Group md="4" controlId="formSurvey">
    <Form.Control md="3" placeholder="ACME Cultural Survey" value={surveyName} onChange={handleInputChange} />
    
    
  </Form.Group>
  <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
    Create Survey
  </Button>
    </Form>
</Container>
    )
    
})

export default NewSurvey
import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { aboutUrl, westrumUrl}  from "./url_functions"
const homeUrl = () => (`http://${window.location.host}`)

const Navigation = () => (
<Navbar bg="success" variant="dark">
    <Navbar.Brand href={homeUrl()}>Cultooling<sup>ALPHA</sup></Navbar.Brand>
    <Nav className="mr-auto">
    </Nav>
    <Nav className="justify-text-end">
    <Nav.Link href={westrumUrl()} className="justify-text-end mr-6">Westrum</Nav.Link>
    </Nav>
    <Nav className="justify-text-end">
    <Nav.Link href={aboutUrl()} className="justify-text-end mr-6">About</Nav.Link>
    </Nav>
    
  </Navbar>
)

export default Navigation
import React from "react";
import { Route, Switch } from "react-router-dom";
import NewSurvey from "./NewSurvey";
import SurveyAdmin from "./SurveyAdmin"
import Respond from "./Respond"
import ThankYou from "../containers/ThankYou"
import SurveyResult from "./SurveyResult"
import Home from "./Home"
import About from "../containers/About"
import AboutWestrum from "../containers/AboutWestrum"
export default () =>
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/about" exact component={About} />
    <Route path="/about/westrum" exact component={AboutWestrum} />
    <Route path="/thank-you" exact component={ThankYou} />
    <Route path="/new" exact component={NewSurvey} />
    <Route path="/surveys/:id/results" component={SurveyResult} />
    <Route path="/surveys/:id" component={SurveyAdmin}  /> 
    <Route path="/respond/:id" component={Respond} />
    
  </Switch>;
import React from "react";
import { Jumbotron } from "react-bootstrap";

let About = () => {
    return (
        <Jumbotron>
            <h1>About Cultooling</h1>
            <hr />
            <h2>Purpose</h2>
            <p>Cultooling strives to make improving organizational culture accessible to everyone.
            This means lowering or removing the barrier to entry in terms of economy, education and time invested.
            Cultooling allows a team in any organization to create a survey, gather responses and within minutes have 
            results that can help them improve their culture.
            </p>
            <p>We believe everyone deserves to work in an environment with a healthy culture, and we know it is good for performance</p>
           <hr />
           <h2>The process</h2>
           Whoever wants to run the survey creates a survey. This gives them a link to share with team members, allowing them to gather responses. 
           As the results come in, Cultooling will show how the team scores on the Westrum Culture Model. 
           When the team has gathered the responses they want, they can consult Cultooling to get some actions and focus areas to work on.
           The results can also be used to spark discussions and reflections on the team, perhaps with an external facilitator.
           <hr />
           <h2>Anonymity</h2>
        
           By default all Cultooling surveys are anonymous, and we do not have any functionality allowing neither survey creators or Cultooling admins to identify respondents.
           Should there be a malicious survey creator, it would be possible to roll out the survey to people individually, and that way de-anonymize the survey by looking at the 
           results as they add more and more respodents. This is not the intention of Cultooling, and organizations practicing things like this should be abandoned, publicly denounced and avoided.
           There are plans to enable more anonymous surveys in the near future.
           <hr />
           <h2>Alpha</h2>
           Cultooling is currently in ALPHA, that means no guarantees other than a best effort at providing some value to people that wants to improve the culture in their organizations.
           Expect change without warning, but it is very unlikely that your data will go missing. At the first stable release we will promote to BETA and hopefully gather more users.
           
            
        </Jumbotron>
    )
}

export default About
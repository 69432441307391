import React from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"


const NewsletterSignupForm = () => {
    return (<>
<div id="mc_embed_signup">
   <Form action="https://randomsort.us18.list-manage.com/subscribe/post?u=37d8461621af0501ab10f42f5&amp;id=63591b6da6" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
    <div id="mc_embed_signup_scroll">
	<h2>Subscribe to Cultooling newsletter</h2>
<Form.Group md="4" controlId="newsletter">
    <Form.Control md="3" className="align-self-center" placeholder="Email" name="EMAIL" id="mce-EMAIL" />
	</Form.Group>

<Form.Group md="4" controlId="newsletter">
		<Form.Control md="3" className="align-self-center" placeholder="First name" name="FNAME" id="mce-FNAME" />
</Form.Group>
<Form.Group md="4" controlId="newsletter">
		<Form.Control md="3" className="align-self-center" placeholder="Last name" name="LNAME" id="mce-LNAME" />
</Form.Group>
<Button variant="primary" type="submit" id="mc-embedded-subscribe">
    Signup
  </Button>
	<div id="mce-responses" className="clear">
		<div className="response" id="mce-error-response" style={{display:"none"}}></div>
		<div className="response" id="mce-success-response" style={{display:"none"}}></div>
	</div>
    <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_37d8461621af0501ab10f42f5_63591b6da6" tabIndex="-1" defaultValue="" /></div>
    </div>
</Form>
</div></>
    )
}

export default NewsletterSignupForm
import React from "react";
import { Card } from "react-bootstrap";

const WestrumCategory = ({ score }) => {
  const pathologic = (
    <Card className="d-flex justify-content-center myCard">
      <Card.Header className="d-flex justify-content-center pathologic">
        Pathologic Culture
      </Card.Header>
      <Card.Body className="d-flex justify-content-center">
        <Card.Text>
          Your score of {score.toFixed(2)} puts you in the Pathologic or Power
          oriented category.
          <br />
          <br />
          This is an indicator that your culture is unhealthy. This means that
          there is a lot of work ahead of you.
          <br />
          Focusing on improving each aspect of the Westrum Culture will be
          painful. <br />
          Much of your organization is set in their ways, carefully managing
          their own power base.
          <br />
          The organization might not be aware that a focus on creating and
          maintaining a healthy culture is necessary. The good news is that
          there is a lot of unrealized potential in your organization to be
          unleashed through focus on improving culture.
        </Card.Text>
      </Card.Body>
    </Card>
  );
  const bureaucratic = (
    <>
      <h2>Bureaucratic Culture</h2>
      <p>
        Your score of {score.toFixed(2)} puts you in the Bureaucratic or Rule
        oriented category.
        <br /> <br />
        This indicates that while your organizational culture is not directly in
        the toxic category, it is probably slow moving. Bureaucratic
        organizations favor their processes and is the embodiment of
        stereotypical <i>Enterprise</i> development.
        <br />
        Processes are followed to the point, disregarding the value they add.
        Pointing out problem areas are met with apathy and statements along the
        line of <i>We have tried that, it will not work here.</i>
        <br />
        Creating a sense of urgency can be difficult in bureaucratic
        organizations with high inertia.
        <br />
        In order to stay competitive it is critical to shift the focus from
        outputs to outcomes.
        <br />
        There should be a stable foundation to build a healthy culture on, now
        push further.
      </p>
    </>
  );
  const generative = (
    <Card bg="success" className="d-flex justify-content-center myCard ">
      <Card.Header className="d-flex justify-content-center generative">
        Generative Culture
      </Card.Header>
      <Card.Body className="d-flex justify-content-center">
        <Card.Text>
          Your score of {score.toFixed(2)} puts you in the Generative or
          Performance oriented category.
          <br /> <br />
          This indicates that your organizational culture is healthy and outcome
          focused.
        </Card.Text>
      </Card.Body>
    </Card>
  );
  if (score > 5) {
    return generative;
  } else if (score < 3) {
    return pathologic;
  } else {
    return bureaucratic;
  }
};

export default WestrumCategory;
